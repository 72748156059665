<template>
  <section id="meetings">
    <v-row v-if="!loading">
      <v-col cols="12" xl="9">
        <div class="text-h5 ml-2 mb-5">Meetings</div>

        <!-- <v-row class="mt-10" >
          <v-col cols="12" md="4" v-for="item in meetings" :key="item.id">
            <v-card
              flat
              style="border-top: 2px solid green; border-radius: 0px"
            >
              <v-card-text>
                <div class="text-h5" :class="user.dark == 1 ? 'white--text': 'black--text'">
                  {{ item.meeting.topic }}
                </div>
                <div class="">
                  <span class="caption" v-if="!more">
                    {{ item.meeting.agenda.slice(0, 100) }}
                  </span>
                  <span class="caption" v-else>
                    {{ item.meeting.agenda }}
                  </span>
                  <span @click="more = !more" class="caption primary--text"
                    >Read {{ more ? "short" : "more" }}...</span
                  >
                </div>
                <div class="mt-4 d-flex justify-space-between align-center">
                  <div class="">
                    <small>START DATE</small>
                    <div class="caption">
                      {{ $helpers.dateFormat(item.meeting.start_time) }}
                    </div>
                  </div>
                  <v-divider vertical class="mx-5" />
                  <div class="">
                    <small>ESTIMATED DURATION</small>
                    <div class="caption">
                      {{ `${item.meeting.duration} minutes` }}
                    </div>
                  </div>
                </div>

                <v-btn
                  class="mt-5 font-weight-bold caption"
                  color="primary"
                  outlined
                  @click="join(item.meeting)"
                >
                  <v-icon size="16" left>mdi-video-outline</v-icon>
                  Join Meeting
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-divider class="my-10"/> -->

        <v-row class="mb-10" >
          <v-col cols="12" md="4" v-for="item in embeds" :key="item.id">
            <v-card
              flat
              style="border-top: 2px solid green; border-radius: 0px"
            >
              <v-card-text>
                <div class="text-h5" :class="user.dark == 1 ? 'white--text': 'black--text'">
                  {{ item.embed.topic }}
                </div>
                <div class="">
                  <div class="caption"
                  :class="
                  item.embed.status == 0 ? '' : item.embed.status == 1 ? 'success--text' : 'red--text'">
                    Status: {{item.embed.status == 0 ? 'Not Started' : item.embed.status == 1 ? 'Live' : 'Endend'}}
                  </div>
                </div>
                <v-btn
                  class="mt-5 font-weight-bold caption"
                  color="primary"
                  outlined
                  @click="joinEmbed(item.embed)"
                >
                  <v-icon size="16" left>mdi-video-outline</v-icon>
                  Join Meeting
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";


export default {
  components: {
  } ,
  data: () => ({
    more: false,
    loading: true,
  }),
  computed: {
    ...mapState("student", {
      meetings: (state) => state.meetings,
      embeds: (state) => state.embeds,
      user: (state) => state.user,
    }),
  },
  created() {
    this.getMeetings().then(() => {
      this.loading = false
    });
    this.getEmbeds()
  },
  methods: {
    ...mapActions("student", [
      "getMeetings",
      "getEmbeds",
    ]),

    join(meeting) {
      if (confirm(`Join in ${meeting.topic} meeting?`)) {
        // this.$router.push({
        //   name: "Student Zoom Session",
        //   params: { uuid: meeting.uuid },
        // });

        let routeData = this.$router.resolve({name: 'Student Zoom Frame Tab', params: { uuid: meeting.uuid },});
        window.open(routeData.href, '_blank');
      }
    },

    joinEmbed(embed) {
      if(embed.status == 1) {
        if (confirm(`Join in ${embed.topic} meeting?`)) {
          this.$router.push({
            name: "Student Embed Session",
            params: { uuid: embed.uuid },
          });
        }

        return
      }

      alert('Meeting has not started or has already ended.')
      
    },
  },
};
</script>